import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import { LayoutService } from '@app/core/services';
import { UserService } from '@app/core/services';
import { CommonService } from '@app/core/services/common.service';
import { I18nService } from '@app/shared/i18n/i18n.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { BsModalRef,ModalDirective } from 'ngx-bootstrap';
import { EmitService } from '@app/ts/services/emit.service';
declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  styleUrls:['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('newBuild') public newBuild:ModalDirective;
  @ViewChild('cookieError') public cookieError:ModalDirective;
  store: any;
  light: any;
  dark: any;
  loadedDarkOptions;
  loadedDarkprofile;
  loadedLightOptions;
  loadedLightprofile;
  lanId: any;
  company;
  langChangeSubscription: Subscription;
  langaugeChanged: boolean = false;
  activitySelected:boolean = false;
  fullScreenSelected:boolean = true;
  notificationSelected:boolean = false;
  companyConfirmed: any;
  isBexioUser: boolean = false;
  headerColor = environment.color;
  buildAlert:String =""
  showerrorAlert:boolean=false;
  constructor(private router: Router,private layoutService: LayoutService,private i18nService: I18nService,private commonService:CommonService,private userService: UserService,private emitService: EmitService) {
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => 
      setTimeout(() => {
        this.onLangChange(langObj);
      },500));

    let style =  this.commonService.getCookie(this.commonService.encrypt('styleCookie'));
    let styleTheme = this.commonService.decrypt(style)
    styleTheme ? this.layoutService.onSmartSkin(JSON.parse(styleTheme)):'';
    let status;
    setTimeout(() => {
      styleTheme?JSON.parse(styleTheme).name == 'smart-style-2' ? $("#themeStyle").prop("checked",true): $("#themeStyle").prop("checked",false):'';      
    });
    this.emitService.newDistEvent.subscribe(data=>{
      if(data){
        this.buildAlert = this.i18nService.getTranslation('build_alert');
        this.newBuild.show();
      }
      
    })
    this.emitService.refreshApiErrorEvent.subscribe(data=>{
      // if(data)
      //   this.cookieError.show();
    })
  }
  onLangChange(langObj: any) {
    langObj.isHandled = true;
    this.loadedDarkOptions = false;
    this.loadedDarkprofile = false;
    this.loadedLightprofile = false;
    if( this.langaugeChanged ){
      setTimeout(() => {
        this.lanId = this.commonService.getCurrentLanguageCode();
        this.loadDarkProfile();
        // this.loadDarkOptions();
      },1000);
    }
    
    this.langaugeChanged = true;
  }

  selectActivity(data){ 
    // this.activitySelected = data;
    if(data.type=='notification'){
      this.notificationSelected = data.value;
    }
    else
      this.activitySelected = data.value;
  }
  selectFullScreen(data){
    this.fullScreenSelected = data;
  }
  
  ngOnInit() {
    this.showerrorAlerts();
    localStorage.getEncryptedItem('isBexioCompany')=='true'?this.isBexioUser=true:this.isBexioUser=false;
    this.store = this.layoutService.store;
    let skins = this.store.skins;
    for (let i = 0; i < skins.length; i++) {
      if(skins[i].name == 'smart-style-1')
      this.dark = skins[i];
      if(skins[i].name == 'smart-style-2')
      this.light = skins[i];
    }
    setTimeout(() => {
      this.loadDarkProfile();
      // this.loadDarkOptions();
    },1000);
    this.commonService.profilemImageChange.subscribe( data => {
      if( data){
        console.log('%c Image changed','color:red');
        this.loadedDarkprofile = false;
        this.loadDarkProfile();
      }
    })
    this.buildAlert = this.i18nService.getTranslation('build_alert')
    // this.loadLightOptions();
    // this.loadLightProfile();
  }
  loadDarkOptions(): void {
    if (this.loadedDarkOptions) return;
    const script = document.createElement('script');
    // script.src = 'assets/webComponent/options.js';
    script.src = environment.WC_APPS;
    document.body.appendChild(script);
    this.loadedDarkOptions = true;
    const content = document.getElementById('webComponetsOptions');
    this.lanId = this.commonService.getCurrentLanguageCode();
    let optiondata: any = {isWork: true,lang:this.lanId};
    optiondata = JSON.stringify(optiondata);
    content.innerHTML = `<custom-button optiondata='${optiondata}'></custom-button>`;
    }
  loadDarkProfile(): void {
    if (this.loadedDarkprofile) return;
    const script = document.createElement('script');
    // script.src = 'assets/webComponent/profile.js';
    script.src = environment.WC_PROFILE;
    document.body.appendChild(script);
    this.loadedDarkprofile = true;
    const content = document.getElementById('webComponetsProfile');
    this.lanId = this.commonService.getCurrentLanguageCode();
    // this.userService.userData().subscribe(res=>{
    //   if(res['status']){
    //     let user = res['data'];
    //     localStorage.setEncryptedItem("userData", JSON.stringify(user));
    //     this.company = user[0].companyDetails[0].legalname;
        let profileData: any = {lang: this.lanId};
        profileData = JSON.stringify(profileData);
        content.innerHTML = `<profile-button profiledata='${profileData}'></profile-button>`;
    //   }
      
    // })
  }
  // loadLightOptions(): void {
  //   if (this.loadedLightOptions) return;
  //   const script = document.createElement('script');
  //   script.src = 'assets/webComponent/optionsLight.js';
  //   document.body.appendChild(script);
  //   this.loadedLightOptions = true;
  //   const content = document.getElementById('webComponetsOptionsLight');
  //   const tile = document.createElement("custom-button1");
  //   content.appendChild(tile);
  //   let copm = document.querySelector('custom-button1')
  //   copm.setAttribute('optiondata', '{ljhjbllhjnk: true}');
  //   }
  // loadLightProfile(): void {
  //   if (this.loadedLightprofile) return;
  //   const script = document.createElement('script');
  //   script.src = 'assets/webComponent/profileLight.js';
  //   document.body.appendChild(script);
  //   this.loadedLightprofile = true;
  //   const content = document.getElementById('webComponetsProfileLight');
  //   const tile = document.createElement("profile-button1");
  //   content.appendChild(tile);
  //   let copm = document.querySelector('profile-button1')
  //   copm.setAttribute('profiledata', JSON.stringify({userName: 'Agreesh V S',mailId: 'a.makwana@edexa.team'}));
  // }

  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }

  routeToProfile(){
    this.router.navigate(['/profile']);
  }
  onSelectTheme(e){

    if(e.currentTarget.checked == true){
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.light)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.light)
      this.commonService.themeChangeSubject.next(true)
      $('.menuIconsOption').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
      $('.menuIcons').siblings('div').first().removeClass(this.dark.name).addClass(this.light.name);
      // document.getElementById("webComponetsOptionsLight").style.display = "block"
      // document.getElementById("webComponetsOptions").style.display = "none"
      // document.getElementById("webComponetsProfileLight").style.display = "block"
      // document.getElementById("webComponetsProfile").style.display = "none"
    }
    else{
      this.commonService.setCookie(this.commonService.encrypt(environment.styleCookie),this.commonService.encrypt(JSON.stringify(this.dark)),10000,environment.cookieDomain,'/')
      this.layoutService.onSmartSkin(this.dark)
      this.commonService.themeChangeSubject.next(true)
      $('.menuIconsOption').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      $('.menuIcons').siblings('div').first().removeClass(this.light.name).addClass(this.dark.name);
      // document.getElementById("webComponetsOptions").style.display = "block"
      // document.getElementById("webComponetsOptionsLight").style.display = "none"
      // document.getElementById("webComponetsProfile").style.display = "block"
      // document.getElementById("webComponetsProfileLight").style.display = "none"
    }
    this.loadedDarkprofile = false;
    // $('profile-button').remove();
    // this.loadDarkProfile();
    
    this.loadedDarkOptions = false;
    // $('custom-button').remove();
    // this.loadDarkOptions();
  }
  clearCache() {
    localStorage.clear();
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
    location.reload()
  }
  showerrorAlerts(){
    this.emitService.refreshApiErroralert.subscribe(data=>{
      if(data){
        console.log(data);
        this.showerrorAlert= true 
        // location.reload()
         
      }
    });
    
  }

  errorAlertOk(){
    this.cookieError.hide();
    localStorage.clear();
    window.location.href = environment.LOGOUT_URL+encodeURIComponent(this.commonService.encrypt(window.location.href))
  }
}
