import {Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '@app/core/services';
import { CommonService } from '@app/core/services/common.service';
import { environment } from '@env/environment';
import { NavigationService } from './navigation.service';
import {LayoutService} from "@app/core/services/layout.service";
import { AppService } from '@app/app.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { CompanyManagementService } from '@app/features/company-management/company-management.service';
declare var $:any

@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls:['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  userName = "";
  accountId:any;
  imageData:any;
  // pendingCount: number = 0;
  companyVerified: boolean = true;
  // companyPendingCount: number = 0;
  permissions: any;
  companyProfileAllowed:boolean = false;
  userManagementAllowed:boolean = false;
  companyManagementAllowed:boolean = false;
  billingAllowed:boolean = false;
  subscriptionsAllowed:boolean = false;
  isNavLoading: boolean;
  navigationList:any = [];
  companyName: any;
  userPermissions:any;
  fileCompareUrl = environment.fileCompareUrl
  constructor(private userService:UserService,private commonService: CommonService,private navigationService:NavigationService,
    private _sanitizer: DomSanitizer, private httpClient: HttpClient,private layoutService: LayoutService,private companyManagementService: CompanyManagementService,
    private _appService:AppService,private router: Router,public activatedroute: ActivatedRoute) {
    this.companyVerified = localStorage.getEncryptedItem('isCompanyConfirmed') == 'true' ? true : false;
    this.commonService.companyDetailsEvent.subscribe(data=>{
      // this.companyPendingCount = 0;
      // this.totalColumns();
    })

  }

  ngOnInit() {

    this.userPermissions = localStorage.getEncryptedItem('userPermissions');
    console.log('userPermissions',this.userPermissions);
    this.commonService.userDataChangeObservable.subscribe((profileState)=>{
      if(profileState){
        // this.changeProfile()
        this.changeUserDeatils()
      }
    });
    this.commonService.profileDataChange.subscribe( data => {
      if( data){
        this.changeProfile();
      }
    })
    
    // this.router.events.subscribe((val) => {
    //   if(val instanceof NavigationEnd){
    //     debugger
    //     this.getNavigationList();
    //   }
    // });

    // let userCookie = this.commonService.getCookie(this.commonService.encrypt(environment.userInfo));
    // let userData = userCookie ? JSON.parse(this.commonService.decrypt(userCookie)) : "";
    // this.userName = userData.userName ? userData.userName : "";
    // this.companyName = userData.companyName ? userData.companyName: "";
    this.userName = localStorage.getEncryptedItem('userName') ? localStorage.getEncryptedItem('userName') : "";
    this.companyName = localStorage.getEncryptedItem('companyName') ? localStorage.getEncryptedItem('companyName') : "";
    // this.companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
    this.getNavigationList();
    let permissionCookie = localStorage.getEncryptedItem('permissions');
    this.permissions = permissionCookie ? this.commonService.decrypt(permissionCookie):'';
    // let fetchProfileData = new FormData();
    // this.accountId = localStorage.getEncryptedItem('accId');
    // fetchProfileData.append("accountId", this.accountId);
    // let object = this.commonService.createObject(fetchProfileData);
    // let token = this.commonService.createToken(object);
    // let encryptedToken = this.commonService.encrypt(token);
    // let encrData = new FormData();
    // encrData.append("encrToken", encryptedToken);
    // this.navigationService.getUserImg(encrData)
    // .subscribe(data => {
    //   if(data['status'])
    //   this.imageData = this._sanitizer.bypassSecurityTrustResourceUrl(data['data']);
    // })
    
  }
  // checkBillingDetails() {
  //   let data = new FormData();
  //   this.accountId = localStorage.getEncryptedItem('accId');
  //   data.append("partyId", localStorage.getEncryptedItem('partyID'));
  //   let object1 = this.commonService.createObject(data);
  //   let token1 = this.commonService.createToken(object1);
  //   let encryptedToken1 = this.commonService.encrypt(token1);
  //   let encrData1 = new FormData();
  //   encrData1.append("encrToken", encryptedToken1);
  //   this.navigationService.checkBillingDetails(encrData1).subscribe(data => {
  //     if(data['status'])
  //     this.pendingCount = data['data'];
  //   })
  // }
  goToProfile() {
    // let companyList = JSON.parse(localStorage.getEncryptedItem('userData'))[0]['companyDetails'];
    // let partyId = companyList.filter((e)=> e.companyname == 'io-market AG');
    // // localStorage.getEncryptedItem('partyID');
    // this.switchCompany(partyId[0].partyid)
    // // window.open(environment.profile_URL, environment.profile_URL)
    // // return false;
  }
  goToAdmin() {
    // window.open(environment.ADMIN_URL, environment.ADMIN_URL)
    // return false;
  }
  // totalColumns(){
  // let data = new FormData();
  // // this.accountId = localStorage.getEncryptedItem('accId');
  // data.append("companyId", localStorage.getEncryptedItem('partyID'));
  // let object = this.commonService.createObject(data);
  // let token = this.commonService.createToken(object);
  // let encryptedToken = this.commonService.encrypt(token);
  // let encrData = new FormData();
  // encrData.append("encrToken", encryptedToken);
  //   this.navigationService.totalColumns(encrData).subscribe(data => {
  //     if(data['status'])
  //     this.companyPendingCount = data['data'];
  //   })
  // }
  getNavigationList(){
    let permissionCookie = localStorage.getEncryptedItem('permissions');
    this.permissions = permissionCookie ? this.commonService.decrypt(permissionCookie):'';
    this.userName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].username : '';
    let companyName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].companyDetails : '';
    // let companyName = [{"cid":9,"companyname":"IOM_IND_TEST","partyid":"19"},{"cid":14,"companyname":"io-market AG Demokonto","partyid":"31"},{"cid":22,"companyname":"AVS_AG_SPORTS","partyid":"39"}]
    // localStorage.getEncryptedItem('userData') ? this.companyName = companyName.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'] : '';
    this.isNavLoading = true;
    this.navigationList = [];
      this.httpClient.get("assets/api/jsonData/navigation/commonNavigation.json").subscribe(data =>{ 
        let navData = this.manipulateTreeData(data['DATA']);
        this.navigationList =  navData;  
        // for(let j=0;j<data['DATA'].length;j++){
        //   this.navigationList.push(data['DATA'][j]);
        // }
        // setTimeout(() => {
          this.isNavLoading = false;
        // },2000)
        
      })
  }

  manipulateTreeData(tree){
    let arrTree = [];
    let sortedArr = [];
    let retSortedArr = [];
    for (let i in tree) {
      let node = tree[i];
      if(typeof node === 'object'){
        let manipulatedNode = this.manipulateSettingsNode(node);
        arrTree.push(manipulatedNode);
        let pos = manipulatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedArr[pos] = manipulatedNode;
        }
      }  
    }
    for (let j in sortedArr) {
      if (sortedArr[j]) {
        retSortedArr.push(sortedArr[j]);
      }
    }
    if (arrTree.length === retSortedArr.length) {
      return retSortedArr;
    }
    return arrTree;
  }

  manipulateSettingsNode(node) {
    let manipulatedNode = {
      children: []
    };
    let sortedNode = {
      children: []
    };
    let retSortedNode = {
      children: []
    };
    for (let i in node) {
      let childData = node[i];
      if (typeof (childData) === 'object') {
        let gManiuplatedNode = this.manipulateSettingsNode(childData);
        manipulatedNode.children.push(gManiuplatedNode);
        let pos = gManiuplatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedNode.children[pos] = gManiuplatedNode;
        }
      } else {
        manipulatedNode[i] = childData;
        sortedNode[i] = childData;
        retSortedNode[i] = childData;
      }
    }
    for (let j in sortedNode.children) {
      if (sortedNode.children[j]) {
        retSortedNode.children.push(sortedNode.children[j]);
      }
    }
    if (manipulatedNode.children.length === retSortedNode.children.length) {
      return retSortedNode;
    }
    return manipulatedNode;
  }
  changeProfile(){
    let formData = new FormData();
    formData.append('code','ADMIN');
    let objectUser = this.commonService.createObject(formData);
    let tokenUser = this.commonService.createToken(objectUser);
    let encryptedTokenUser = this.commonService.encrypt(tokenUser);
    let encrDataUser = new FormData();
    encrDataUser.append("encrToken", encryptedTokenUser);
    this.userService.userData(encrDataUser).subscribe( data=>{
      let user = data['data'];
      let permissions = this.commonService.encrypt(String(user[0].permissions.Allow));
      // this.commonService.setCookie(environment.permissions,permissions,10000,environment.cookieDomain,'/');
      localStorage.setEncryptedItem('permissions',permissions);
      localStorage.setEncryptedItem('userData', JSON.stringify(user));
      localStorage.setEncryptedItem('accId',user[0].accId);
      localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
      localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
      localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
      localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
      let userInfo = {};
      userInfo["userName"] = user[0].userName;
      userInfo["companyName"] =  this.companyName;
      // this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
    });
  }
  changeUserDeatils(){
    let formData = new FormData();
    // formData.append('code','ACCOUNTS')
    formData.append("partyID",localStorage.getEncryptedItem('partyID'));
    let objectUser = this.commonService.createObject(formData);
    let tokenUser = this.commonService.createToken(objectUser);
    let encryptedTokenUser = this.commonService.encrypt(tokenUser);
    let encrDataUser = new FormData();
    encrDataUser.append("encrToken", encryptedTokenUser);
    this.navigationService.getCompanyDetails(encrDataUser).subscribe(res=>{
      let user = res['data'];
      this.companyName = user[0].companyname;
      let userInfo = {};
      userInfo["userName"] = this.userName;
      userInfo["companyName"] =  this.companyName;
      // this.commonService.setCookie(this.commonService.encrypt(environment.userInfo),this.commonService.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
      localStorage.setEncryptedItem('companyNameData',this.companyName ? this.companyName : '');
    });     
  }
  switchCompany(partyId){
    let formData = new FormData();
    formData.append('partyId',partyId);
    formData.append('accId',localStorage.getEncryptedItem('accId'));
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    this.navigationService.switchCompany(encrData).subscribe(res=>{
      if(res['status']) {
        // localStorage.setEncryptedItem('userMail',company.email);
          localStorage.setEncryptedItem('partyID',partyId);
        // localStorage.setEncryptedItem('companyNameData',company.companyname);
        // localStorage.setEncryptedItem('companyEmailData',company.website);
        localStorage.removeEncryptedItem('userData');
        // console.log(company.fk_party);
        // window.open(environment.GATE2BURL,"_blank");
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
      
    })
  }
}
