import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { catchError ,tap} from 'rxjs/operators';
import { environment } from '@env/environment';
import * as crypto from 'crypto-browserify';
import { CommonService } from '@app/core/services/common.service';
import { EmitService } from '@app/ts/services/emit.service';
declare var Buffer: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  encryptionKey: string;
  excludeTokenVerification =[]
  constructor(
    private injector: Injector,
    public authToken: AuthTokenService,
    private commonService: CommonService,
    private emitService: EmitService,
    public store: Store<fromAuth.AuthState>
  ) { 
    this.encryptionKey = environment.encryptionKey;
    this.excludeTokenVerification= [`${environment.API_END_POINT_MTC}auth/authlogin`]
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.body instanceof FormData) {
      // if()
      if (!request.body.has('accIdLog')) {
        request.body.append('accIdLog', localStorage.getEncryptedItem('accId'));
        request.body.append('accountId', localStorage.getEncryptedItem('accId'));

      }
      request =  request.clone({
        body: request.body
      })
    }
      request = request.clone({
        withCredentials: true
      });
    if (request.url.search(environment.API_END_POINT_MTC) === 0) {
      // attach token
      let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.mtc_cookie)));
      if(cookieExist) {
        cookieExist = JSON.parse(localStorage.getEncryptedItem('loginCookieData'));      
        // if((localStorage.getEncryptedItem('partyID')&&localStorage.getEncryptedItem('partyID')!=cookieExist['partyID']) || (localStorage.getEncryptedItem('userID')&&localStorage.getEncryptedItem('userID')!=cookieExist['userID'])) {
        //   localStorage.removeEncryptedItem('userData');
        //   localStorage.setEncryptedItem('partyID',cookieExist['partyID'])
        //   localStorage.setEncryptedItem('userID',cookieExist['userID'])
        //   location.reload();
        // }
      }
      return this.handleApiRequest(request, next);
    } else {
      // return next.handle(request);
      return this.handleApi(request,next);
    }
  }



  handleApiRequest(request, next) {    
    // let request1 = request.clone({ headers: request.headers.set('withCredentals', 'true') })
    const handler = next.handle(request).pipe(
      tap(res=> {
        if (res instanceof HttpResponse) {
               if(res.body.data=='cookie_not_found'){
                this.emitService.subscribeapiError(true);  
               }
                                               
        }
    }),
    catchError((error: any) => {
        if ((error.status === 401 || error.status === 403 ) && request.url != environment.API_END_POINT_MTC+'/authlogin') {
          console.log('here');
          console.log(request.url);
          let currentURL =environment.MTCURL+'companyManagement';
          window.location.href = environment.LOGOUT_URL+encodeURIComponent(this.commonService.encrypt(currentURL))
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
  handleApi(request,next) {
    const handler = next.handle(request).pipe(
      tap(res=> {
          if (res instanceof HttpResponse) {  
                 if(res.body.data=='cookie_not_found'){
                  this.emitService.subscribeapiError(true); 
                 }
                                                  
          }
      }));
    return handler;
   }
}
