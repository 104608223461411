import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
// import { LoginInfoComponent } from '@app/shared/user/login-info/login-info.component';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private appService : AppService,private commonservice:CommonService,private http: HttpClient,private emitService: EmitService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    console.log(route);
    
    let cookieExist = this.commonservice.getCookie(environment.mtc_cookie);
    if (!localStorage.getEncryptedItem('loginCookieData') || cookieExist == "") {
      // let incomingPath = window.location.href.split('/auth/login')[1];
      localStorage.clear();
      this.router.navigate(['/auth/login']);
      return true;
    }
    else{
      let expiredCookie = this.commonservice.decrypt(decodeURIComponent(cookieExist))
      if (this.tokenExpired(expiredCookie)) {
        // token expired
        this.appService.createAccessToken().subscribe(data=>{ });
      } else {
        // token valid
      }

      if( !localStorage.getEncryptedItem('userData') ){
        let formData = new FormData();
        formData.append('code','ADMIN');
        let objectUser = this.commonservice.createObject(formData);
        let tokenUser = this.commonservice.createToken(objectUser);
        let encryptedTokenUser = this.commonservice.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        let tempPath = window.location.hash.replace('#/','') == '' ? 'companyManagement' : window.location.hash.replace('#/','');
        console.log(tempPath);
        
        this.http.post(environment.API_END_POINT_MTC+'/userProfile',encrDataUser).subscribe(dataFromApi => {
          let user = dataFromApi['data'];
          let permissions = this.commonservice.encrypt(String(user[0].permissions.Allow));
          // this.commonservice.setCookie(environment.permissions,permissions,10000,environment.cookieDomain,'/');
          localStorage.setEncryptedItem('permissions',permissions);
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          // localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
          // localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
          // localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
          // localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
          // localStorage.setEncryptedItem('currency',user[0].currency?.toString());
          // let userName = user[0].username;
          // let companyList = user[0].companyDetails;
          // let companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
          // let userInfo = {};
          // userInfo["userName"] = userName;
          // userInfo["companyName"] = companyName;
          // this.commonservice.setCookie(this.commonservice.encrypt(environment.userInfo),this.commonservice.encrypt(JSON.stringify(userInfo)),10000,environment.cookieDomain,'/');
          if(this.commonservice.decrypt(localStorage.getEncryptedItem('permissions')).indexOf(route.data.right)==-1){
            window.location.href = environment.MTCURL;
            return
          }
          window.location.href = environment.MTCURL + tempPath ;
          setTimeout(() => {
            let language = user[0].language ? user[0].language : 'de';
            this.emitService.emitLoginLanguageChange(language);
          },100);
        });
      } 
      else{
        if(this.commonservice.decrypt(localStorage.getEncryptedItem('permissions')).indexOf(route.data.right)==-1){
          window.location.href = environment.MTCURL+'auth/logout';
          return

        }        
        return true;
      }
    }
    // return true;
  }

  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }

}
